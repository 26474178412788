import styled, { css } from "styled-components"
import { motion, MotionStyle } from "framer-motion"
import { useState, useEffect, useRef } from "react"

import FileSaver from "file-saver"

import CardAltCV from "https://framer.com/m/Card-Alt-CV-O7jC.js"
import AltCvButton from "https://framer.com/m/AltCv-Button-LkBW.js"
import AltCvActionButton from "https://framer.com/m/AltCvActionButton-8gYD.js"

interface Question {
    category: string
    question: string
    hint?: string
    selected?: boolean
}

const questionData: Question[] = [
    {
        category: "Purpose",
        question: "Where do you draw meaning in your life most?",
        hint: "Opens up conversations about purpose and meaning of one’s life and work",
    },
    {
        category: "Purpose",
        question:
            "Tell me the story of your interests in your work and how you came to this point right now",
        hint: "Can bring wildly different starting points; the key is to dive into the less conventional hints and follow their trajectories.",
    },
    {
        category: "Developmental",
        question: "What is one of your favorite early memories?",
    },
    {
        category: "Developmental",
        question:
            "How did you experience elementary school? (Academically? Socially?)",
    },
    {
        category: "Developmental",
        question:
            "How did you experience high school? (Academically? Socially?)",
    },
    {
        category: "Developmental",
        question:
            "If you went to post secondary school, what kind and why? How did you experience post-secondary school? (Academically? Socially?)",
    },
    {
        category: "Developmental",
        question:
            "What are some of the most important lessons you learned from your parents, if any?",
    },
    {
        category: "Developmental",
        question:
            "Did you have siblings and how do you think that impacted on your social self?",
    },
    {
        category: "Developmental",
        question:
            "What do you NOT want to forget about who you were as a young person (e.g., I used to love jazz, or dancing, or I used to run a film club I should get back to)?",
    },
    {
        category: "Curiosity",
        question:
            "What are you most curious about these days? What were you most curious about as a child and/or adolescent? What have you never thought about, done, experimented with, read, seen, etc that you one day wish you can?",
    },
    {
        category: "Curiosity",
        question:
            "How do you pursue your curiosities (e.g., is it what you do when you procrastinate, is it driven by a lover/friend, are you constantly trying to find something new that will be risky or exciting?)",
        hint: "What is it that pulls people's attention towards something? Not just what that thing IS (the content) but also what is the process by which people are moved towards that thing... what do they use to move them towards that thing",
    },
    {
        category: "Aspiration",
        question:
            "What were your aspirations as a teenager? Why? Did they change? Why? How close have you come to reaching them, how do you feel about that distance? Did you have other interests or obsessions in adolescence?",
    },
    {
        category: "Aspiration",
        question:
            "What are your current aspirations (beyond pure work, which is also what we're interested in, but not exclusively)? Why?",
    },
    {
        category: "Collaboration",
        question:
            "How do you respond to positive feedback, when people praise you or your work?",
    },
    {
        category: "Collaboration",
        question:
            "How do you respond to negative feedback, when people are critical of you or your work?",
    },
    {
        category: "Collaboration",
        question:
            "Would you rather work in a group or alone? Under what conditions, or during what types of activities, do you prefer one over the other?",
    },
    {
        category: "Emotional experiences",
        question:
            "Tell a story when you feel you've been compassionate towards another?",
    },
    {
        category: "Emotional experiences",
        question:
            "Tell a story when you feel someone else was particularly compassionate towards you?",
    },
    {
        category: "Emotional experiences",
        question:
            "Tell a story when you WISH you'd been more compassionate towards another",
    },
    {
        category: "Emotional experiences",
        question: "When was the last time you were in a state of flow?",
    },
    {
        category: "Emotional experiences",
        question:
            "Have you ever had a transformative experience, however you define it (religious, therapeutic, relational, near-death, etc.)",
    },
    {
        category: "Emotional experiences",
        question: "Are you happy in your life? Why/why not?",
    },
    {
        category: "Emotional experiences",
        question:
            "Do you seek happiness in your life or is there some other emotional state that you aspire towards?",
    },
    {
        category: "Interests",
        question:
            "Do you have particular maker skills (cooking, coding, origami, knot-making, building huts)",
    },
    {
        category: "Interests",
        question:
            "What do you do that brings you joy or peace or challenges you that you don't consider work? (hobbies, sports, etc).",
        hint: "Hobbies like woodwork, playing music, poetry, painting, re-enactment, writing, pottery, iron metalwork,leather work, cooking, tea ceremonies, ritual drumming circles, etc.",
    },
    {
        category: "Interests",
        question:
            "Do you have particular maker skills (cooking, coding, origami, knot-making, building huts)",
    },
    {
        category: "Interests",
        question: "Are you superstitious? If so, how?",
    },
    {
        category: "Interests",
        question: "What's your chronotype? Has it changed over your lifespan?",
    },
    {
        category: "Interests",
        question:
            "Do you read mostly fiction or nonfiction and what are some of your favourites of one or both?",
    },
    {
        category: "Interests",
        question: "Favourite movies?",
    },
    {
        category: "Interests",
        question: "Favourite music?",
    },
    {
        category: "Interests",
        question:
            "Favourite tech (app, game, platform, garden lights, books, etc.)",
    },
    {
        category: "Interests",
        question:
            "Who is or was your role model? Can be someone you know or a celebrity or someone dead that you know through reading or watching.",
    },
    {
        category: "Interests",
        question:
            "What the difference (and do you care) between science and art?",
    },
    {
        category: "Interests",
        question:
            "Do you feel like you belong? Where do you feel that? When did you feel that?",
    },
    {
        category: "Interests",
        question:
            "Do you have particular maker skills (cooking, coding, origami, knot-making, building huts)",
    },
    {
        category: "Interests",
        question: "What's a joke you remember?",
    },
    {
        category: "Interests",
        question: "Do you like silence? When, how, how often?",
    },
    {
        category: "Interests",
        question: "When have you been lucky?",
    },
    {
        category: "Interests",
        question:
            "Have you ever felt, or do you feel: weltschmerz: [noun] mental depression or apathy caused by comparison of the actual state of the world with an ideal state.",
    },
    {
        category: "Interests",
        question:
            "On a scale from 1 to 10, what's your trust in yourself? Others? How hopeful you are?",
    },
]

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/developers/#code-components-auto-sizing
 *
 * @framerSupportedLayoutWidth auto
 * @framerSupportedLayoutHeight auto
 */
export default function AltCvCards(props) {
    const selectedCategory = "__selected"
    const [questions, setQuestions] = useState(questionData)
    const [category, setCategory] = useState(questionData[0].category)
    const [disabled, setDisabled] = useState(false)

    const numSelected = (questions: typeof questionData): number => {
        return questions.filter((q) => q.selected).length
    }

    return (
        <motion.div>
            <ButtonBar style={{ opacity: disabled ? 0.5 : 1 }}>
                {questions
                    .map((q) => q.category)
                    .filter((cat, i, arr) => arr.indexOf(cat) === i)
                    .map((cat) => (
                        <Button
                            text={cat}
                            variant={
                                cat == category ? "Variant 2" : "Variant 1"
                            }
                            activate={() => {
                                setCategory(cat)
                            }}
                        />
                    ))}
                {numSelected(questions) > 0 ? (
                    <Button
                        text="Preview set"
                        variant={
                            category == selectedCategory
                                ? "Variant 2"
                                : "Variant 1"
                        }
                        activate={() => {
                            setCategory(selectedCategory)
                        }}
                    />
                ) : null}
            </ButtonBar>
            <Container style={{ opacity: disabled ? 0.5 : 1 }}>
                {questions.map((q, idx) =>
                    q.category == category ||
                    (category == selectedCategory && q.selected) ? (
                        <Card
                            key={idx}
                            category={q.category}
                            questionText={q.question}
                            fAQ={q.hint}
                            fAQVisible={"hint" in q}
                            categoryVisible={"category" in q}
                            variant={q.selected ? "Selected" : "Variant 1"}
                            activate={() => {
                                const q = [...questions]
                                q[idx].selected = true
                                setQuestions(q)
                            }}
                            deactivate={() => {
                                const q = [...questions]
                                q[idx].selected = false
                                setQuestions(q)

                                if (
                                    numSelected(q) == 0 &&
                                    category == selectedCategory
                                ) {
                                    setCategory(questionData[0].category)
                                }
                            }}
                        />
                    ) : null
                )}
            </Container>
            {numSelected(questions) > 0 ? (
                <ActionButton
                    style={{ opacity: disabled ? 0.5 : 1 }}
                    text="Create set"
                    activate={() => {
                        setDisabled(true)
                        getPDF(questions)
                        setDisabled(false)
                    }}
                />
            ) : null}
        </motion.div>
    )
}

async function getPDF(q: Question[]) {
    interface QuestionsJSON {
        questions: Question[]
    }

    const r: QuestionsJSON = {
        questions: q.filter((qq) => qq.selected),
    }

    const fileName = "Alt-CV.pdf"
    return await fetch("https://bloom-pdf.w.ikkit.com/pdf/altcv/" + fileName, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(r),
    })
        .then(function (response) {
            return response.blob()
        })
        .then(function (blob) {
            FileSaver.saveAs(blob, fileName)
        })
}

const Container = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
`

const ButtonBar = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
`

const Button = styled(AltCvButton)`
  margin: 10px 10px 10px 0;
  display: flex;
`

const ActionButton = styled(AltCvActionButton)`
  display: flex;
`

const Card = styled(CardAltCV)`
  margin: 10px 10px 10px 0;
`
